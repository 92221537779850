/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    //Siderbar Menu

    $(document).ready(function () {
        $('.menu li a').addClass('smoothScroll');
        $('#sidebar ul li a').addClass('mobile-menu-item');
        $('#dismiss, .overlay').on('click', function () {
            $('#sidebar').removeClass('active');
            //$('.overlay').fadeOut();
        });

        $('#sidebarCollapse').on('click', function () {
            $('#sidebar').addClass('active');
            //$('.overlay').fadeIn();
            $('.collapse.in').toggleClass('in');
            $('a[aria-expanded=true]').attr('aria-expanded', 'false');
        });
    });

    //Smooth Scrolling Mobile

    $(function () {
        // This will select everything with the class smoothScroll
        // This should prevent problems with carousel, scrollspy, etc...
        $('.mobile-menu-item').click(function () {
            $('#sidebar').toggleClass('active');
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                if (target.length) {
                    $('html,body').stop().animate({
                        scrollTop: target.offset().top - 150
                    }, 1000); // The number here represents the speed of the scroll in milliseconds
                    //console.log(target.offset().top);
                    return false;
                }
            }

        });

    });

    //Smooth Scrolling Normal

    $(function () {
        // This will select everything with the class smoothScroll
        // This should prevent problems with carousel, scrollspy, etc...
        $('.smoothScroll').click(function () {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                if (target.length) {
                    $('html,body').stop().animate({
                        scrollTop: target.offset().top - 150
                    }, 1000); // The number here represents the speed of the scroll in milliseconds
                    //console.log(target.offset().top);
                    return false;
                }
            }

        });

    });


    //Back to TOP
    $(document).ready(function() {

        // Show or hide the sticky footer button
        var h = $(".header-bar").height();
        $(window).scroll(function() {
            if ($(this).scrollTop() > h) {
                $('.go-top').fadeIn(700);
            } else {
                $('.go-top').fadeOut(700);
            }
        });

        // Animate the scroll to top
        $('.go-top').click(function(event) {
            event.preventDefault();
            $('html, body').animate({scrollTop: 0}, 800);
        });
    });

    //Jquery Animation for Different Sections
    // GENERAL SETTING
  /*  window.sr = ScrollReveal({
        reset: true,
        mobile: false
    });

// Custom Settings
    sr.reveal('.slide-in-from-left', {
        opacity: 0,
        scale: 1,
        origin: 'left',
        distance: '100%',
        duration: 500,
        delay: 0,
        easing: 'linear',
        viewFactor: 0.4
    });

    sr.reveal('.slide-in-from-right', {
        opacity: 0,
        scale: 1,
        origin: 'right',
        distance: '100%',
        duration: 500,
        delay: 0,
        easing: 'linear',
        viewFactor: 0.4
    });
    sr.reveal('.slide-in-from-top', {
        opacity: 0,
        scale: 1,
        origin: 'top',
        distance: '100%',
        duration: 500,
        delay: 0,
        easing: 'linear',
        viewFactor: 0.4
    });
    sr.reveal('.slide-in-from-bottom', {
        opacity: 0,
        scale: 1,
        origin: 'bottom',
        distance: '100%',
        duration: 500,
        delay: 0,
        easing: 'linear',
        viewFactor: 0.4
    });*/


    ///CAROUSEL ITEMS TO GENERATE
    $(document).ready(function() {

        $('#teamCarousel .carousel-item').each(function () {
            var next = $(this).next();
            if (!next.length) {
                next = $(this).siblings(':first');
            }
            next.children(':first-child').clone().appendTo($(this));

            for (var i = 0; i < 2; i++) {
                next = next.next();
                if (!next.length) {
                    next = $(this).siblings(':first');
                }
                next.children(':first-child').clone().appendTo($(this));
            }
        });
    });

})(jQuery); // Fully reference jQuery after this point.
